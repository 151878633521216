.auth-screen {
    height: 110vh;
    display: flex;
    flex-direction: row;
    min-height: 100vh !important;
  }
  
  
  
  .auth-screen-header {
    background: linear-gradient(90deg, #3A609B 0.03%, rgba(0, 0, 0, 0) 100.03%),
                linear-gradient(0deg, #564FAA, #564FAA);
    padding:1em 5em;
    flex: 60em;
    /* height: 100vh; */
    /* max-height:1%; */
    
  }
  
  
  .svg-container {
    width: 100%;
    /* margin-left: 5%; */
    height: 80%;
  }
  
  .Clocksession_preview-svg {
    width: 100%;
    height: 450px;
  
  }
  
  
  
  
  .auth-screen-form-container {
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 40em;
    /* overflow: auto;  */
    /* box-sizing: border-box; */
  }
  
  .auth-screen-card-container {
    display: flex;
    justify-content: center;
    width: 60%;
  }
  
  .auth-screen-form-card {
    background-color: #FFFFFF;
    padding: 0px;
    width: 300%;
    /* max-width: 450px; */
    height: 30%;
    margin-top: 10%;
  }
  
  /* .auth-screen-form-card h1 {
    color: #242954;
    font-size: 34px;
    font-weight: 800;
    margin-bottom: 15px;
  } */
  /* .auth-screen-form-card h2 {
    color: var(--bs-dark);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    font-family: "FK Grotesk";
  } */
  
  .auth-screen-form-card button:disabled {
    background-color: var(--app-disable-blue-color);
  }
  
  .auth-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .auth-input p {
    color: red;
    font-size: 12px;
  }
  
  .auth-input label{
    color: "#263238";;
    font-size: 12px;
  }
  .form-check-label {
    color: var(--app-text-gray);
    font-size: 12px;
  }
  
  .auth-input input {
    border-top: none;
    border-bottom: 2px solid var(--app-text-gray);
    border-left: none;
    border-right: none;
    height: 30px;
    width: inherit;
    /* margin-top: 0px; */
    background-color: var(--app-simple-white-color);
    font-size: 15px;
    padding-left: 7px;
  }
  
  .auth-input input:focus {
    outline: none;
  }
  
  .auth-screen-form-card input[type="checkbox"]:checked {
    background-color: var(--app-yellow-color);
    color: #99a1a7;
    border: none;
    margin-right: 5px;
    margin-top: 6px;
  }
  
  .auth-input-remember-me {
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .form-check-input[type=checkbox]{
    border-radius: 0;
  }
  .auth-btn {
    width: 100%;
    border: none;
    background-color: #323692;
    height: 30px;
    color: #FFFFFF;
    /* border-radius: 10px; */
    font-weight: bold;
    cursor: pointer;
  }
  
  .forgot-password {
    color: black;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 1%; 
  }
  /* .forgot-password p {
    font-size: 15px;
  } */
  /* .signup-text {
    font-size: 15px;
    color: black;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  
  } */
  .forgot-password span {
    color: #323692;
    margin-left: 7px;
    cursor: pointer;
  }
  
  .copyright-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .copyright-container p {
    margin: 0 0 0 0%;
    color: #FFFFFF;
  }
  
  /* .label-name {
    font-weight: bold;
  } */
  
  .auth-password {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .auth-password button {
    border-bottom: 2px solid var(--app-text-gray) !important;
  }
  /* .horizontal-line {
    width: 100%;
    height: 10; 
    border-bottom: 2px solid #323692;
  } */
  /* .SignUpButton {
    background-color: var(--app-simple-white-color);
    border: 2px solid #323692;
    border-radius: 8px;
    color: #323692;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    height: 3em;
    align-content: center;
    min-width: 5em;
  } */
  /* 
  .SignUpButton:hover {
    background-color: #f5f5f5;
  } */
  .Policy-services{
    display: grid;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #323692;
    /* margin: 9em 1em 1em 1em; */
    height: 70px;
  
    
  }
  .policy{
    font-size:medium;
    align-self: center;
    
    margin-top: 40px;
  
  }
  .about{
    margin: 0 0 0 3%;
    color: #FFFFFF;
    font-size: 10px;
    
    margin-top: 0px;
    margin-bottom: 0px;
  
  }
  /* .policy {
    font-size: medium;
    position: fixed;
    bottom: 0;
    left: 3%;
    color: #FFFFFF;
    font-size: 10px;
  } */
  .about-content{
      margin-top: 0px;
      margin-bottom: 0px;
  }