.auth-screen1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.auth-screen-header1 {
  background-color: indigo;
  padding: 10px 40px;
}

.auth-screen-form-container1 {
  background-color: var(--app-gray-white);
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.auth-screen-card-container1 {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.auth-screen-form-card1 {
  background-color: var(--app-simple-white-color);
  /* border-radius: 30px; */
  padding: 30px;
  width: 100%;
  max-width: 450px;
}

.auth-screen-form-card h11 {
  color: var(--bs-dark);
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 30px;
}

.auth-input1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.auth-input p1 {
  color: red;
  font-size: 12px;
}

.auth-input label1,
.form-check-label1 {
  color: var(--app-text-gray);
  font-size: 12px;
}

.auth-input input:focus1 {
  outline: none;
}

.auth-btn1 {
  width: 100%;
  border: none;
  background-color: #323692;
  height: 54px;
  color: var(--app-simple-white-color);
  /* border-radius: 10px; */
  font-weight: bold;
  cursor: pointer;
}

.copyright-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright-container p1 {
  margin: 0 0 15px 0;
}

.label-name1 {
  font-weight: bold;
}