
/* styles.css */
.login-card {
  margin: auto;
  /* margin-top: 100px; */
  /* max-width: 400px; */
  /* margin: auto; */
  margin-top: 30%;
  /* padding: 16px; */
  /* background: linear-gradient(0deg, #C8CCDE, #C8CCDE),
              linear-gradient(0deg, #DADADA, #DADADA); */
  /* border: 1px solid #C8CCDE; */
  /* box-shadow: 0px 4px 4px 0px #00000040; */
}

.login-button {
  width: 100%;
  margin-top: 16px;
  background-color: #242954 !important;
  color: #ffffff;
  margin-top: 16px;
}

.login-button:hover {
  background-color: #1a1f38 !important; 
}
.login-title {
  /* text-align: center; */
  color: #242954;
}


.login-title-h2 {
  color: #263238;
  font-size: 1.1em;
  /* text-align: center; */
  margin-top: 1%;
}

.input-label {
  font-size: 80%;
  font-weight: 500;
  /* margin: 10px 0 0 0; */
  /* margin-bottom: 8px; */
  /* display: block; */
}

.input-field {
  width: 100%;
  /* margin-bottom: 16px; */
  /* background: linear-gradient(0deg, #C8CCDE, #C8CCDE),
  linear-gradient(0deg, #DADADA, #DADADA); */
  /* border: 1px solid #C8CCDE; */
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  /* padding: 8px; */
  background:#ffffff

}

.password-wrapper {
  position: relative;
}

.password-input {
  width: calc(100% - 3px);
}

.eye-button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1px; /* Adjust the value as needed */
}


.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  
}

.remember-me-checkbox {
  margin-right: 8px;
}
.forgot-password {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 1%; 
}
.forgot-password p {
  font-size: 12px;
  margin-bottom: 1em;
}
.signup-text {
  font-size: 15px;
  color: #242954;
  display: flex;
  justify-content: center;
  margin: 1em 0 0 0;

}

.forgot-password span {
  color: #323692;
  margin-left: 7px;
  cursor: pointer;
}
.SignUpButton {
  background-color: var(--app-simple-white-color);
  border: 2px solid #323692;
  border-radius: 8px;
  color: #242954;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  height: 3em;
  align-content: center;
  min-width: 5em;
}

.SignUpButton:hover {
  background-color: #f5f5f5;
}
.horizontal-line {
  width: 100%;
  /* height: 10;  */
  border-bottom: 2px solid #323692;
}
.form{
  min-height: 100%;
  /* margin-top: 10%; */
}
/* styles.css */

/* Add styles for the input elements, including right padding for the placeholder text */
.input-field input::placeholder {
  padding-right: 10px; /* Set the desired right padding */
  font-size: 14px; /* Set the desired font size */
}
